const fonts: { [k: string]: string } = {
  opensans: "Open Sans, sans-serif",
  caveat: "Caveat, cursive",
  monospace:
    'Menlo, "Cascadia Code", "Operator Mono", "Fira Code", "Dank Mono", Consolas, monospace',
};

fonts.body = fonts.opensans;
fonts.heading = fonts.opensans;
fonts.cursive = fonts.caveat

const fontSizes = [14, 16, 18, 20, 26, 32, 36, 42, 64, 96].map(
  (px) => `${px / 16}rem`
);
const fontWeights = {
  book: 300,
  body: 400,
  highlight: 700,
  heading: 700,
  semibold: 600,
  bold: 700,
  xBold: 800,
};
const lineHeights = {
  body: 1.5,
  heading: 1.25,
};
const letterSpacings = {
  body: "normal",
  caps: "0.2em",
};

export { fonts, fontSizes, fontWeights, lineHeights, letterSpacings };
