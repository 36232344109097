const grays = ["#E8E8E8", "#707070", "#000000CC", "#393739"];

const brand = {
  paleBlue: "#F1FAFD",
  navyBlue: "#145571",
  darkBlue: "#145571",
  brightBlue: "#00A5E0",
  linkBlue: "#007bff",
  blue50: "#BFE6E8",
  blue25: "#D9F2FA",
  orange: "#F78D20",
  yellow: "#FFCD1E",
  coral: "#EE2154", 
  black: "#01283C",
  lightGrey: "#f7f7f7"
};  
export const colors = {
  text: brand.darkBlue,
  grayText: "#393739",
  background: grays[0],
  primary: brand.black,
  secondary: brand.brightBlue,
  accent: "#044c8a",
  placeholder: "#707070",
  brand,
};
