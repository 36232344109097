module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LendDirect","short_name":"LendDirect","description":"Stop settling for typical installment loans. With a line of credit, get unlimited cash advances, up to your credit limit, without having to reapply. Apply for a personal loan online today.","start_url":"/?utm_source=installed","background_color":"#f8f8f9","theme_color":"#3EBFB9","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"maskable badge any"},"categories":["finance","business"],"dir":"ltr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33eb4a3f5c6f3fb27d6c93862d6558db"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5MC3BF","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"bix4jel"},"google":{"families":["Open Sans:300,400,700,800","Caveat:400"]}},
    },{
      plugin: require('../../node_modules/@pittica/gatsby-plugin-trustpilot-widget/gatsby-browser.js'),
      options: {"plugins":[],"username":"www.lenddirect.ca","template":"539ad0ffdec7e10e686debd7","business":"599affea0000ff0005a95acd","height":"365px","language":"en","culture":"US","theme":"light","width":"100%"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
