const base = {
  appearance: "none",
  outline: "none",
  display: "inline-block",
  textAlign: "center",
  lineHeight: "inherit",
  textDecoration: "none",
  fontSize: "inherit",
  fontFamily: "body",
  letterSpacing: "button",
  m: 0,
  px: 3,
  py: 2,
  border: 0,
  transitionProperty: "opacity, box-shadow, transform, background-color",
  transitionDuration: "150ms",
  transitionTimingFunction: "ease-out",
  "&[disabled]": {
    opacity: 0.5,
    boxShadow: "none",
    pointerEvents: "none",
  },
  "&:focus": {
    boxShadow: "outline",
  },
};
export const buttons = {
  base,
  nav: {
    ...base,
  },
  primary: {
    ...base,
    py: 3,
    px: 4,
    position: "relative",
    backgroundColor: "brand.green",
    backgroundOrigin: "padding-box, border-box",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box, border-box",
    border: "3px solid transparent",
    boxShadow: "medium",
    borderRadius: 9999,
    color: "white",
    fontSize: 4,
    lineHeight: 1.3333333,
    "&:hover": {
      boxShadow: "large",
      transform: "translateY(-2px)",
    },
    "&:active": {
      transform: "translateY(0)",
    },
  },
  primaryOrange: {
    ...base,
    letterSpacing: ".75px",
    backgroundColor: "brand.orange",
    ":hover": {
      backgroundColor: "#d87b1a;",
      color: "white",
    },
    color: "black",
    textDecoration: "none",
    borderRadius: "3px",
    fontSize: 2,
    fontWeight: "semibold",
    py: "7px",
    px: 3,
    width: "100%",
    maxWidth: 400,
    border: "solid 3px",
    borderColor: "rgba(0,0,0,0)",
  },
  primaryBlue: {
    ...base,
    letterSpacing: ".75px",
    backgroundColor: "brand.brightBlue",
    ":hover": {
      backgroundColor: "#008abb;",
      color: "white",
    },
    color: "white",
    textDecoration: "none",
    borderRadius: "3px",
    fontSize: 2,
    py: "7px",
    px: 3,
    width: "100%",
    maxWidth: 400,
    border: "solid 3px",
    borderColor: "rgba(0,0,0,0)",
  },
  outlineLight: {
    ...base,
    maxWidth: 400,
    textDecoration: "none!important",
    borderRadius: "5px",
    color: "white!important",
    border: "solid 1px white",
    ":hover": {
      backgroundColor: "white",
      color: "#145571!important",
    },
  },
  outlineDark: {
    ...base,
    maxWidth: 400,
    textDecoration: "none!important",
    borderRadius: "5px",
    color: "#145571!important",
    border: "solid 1px #145571",
    ":hover": {
      backgroundColor: "#145571",
      color: "#fff!important",
    },
  },
};
